@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&family=Bebas+Neue&family=Black+Han+Sans&display=swap");
@font-face {
    font-family: "Barlow Semi Condensed", sans-serif;
    src: url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&family=Bebas+Neue&family=Black+Han+Sans&display=swap");
}

@font-face {
    font-family: "Bebas Neue", cursive;
    src: url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&family=Bebas+Neue&family=Black+Han+Sans&display=swap");
}

@font-face {
    font-family: "Black Han Sans", sans-serif;
    src: url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&family=Bebas+Neue&family=Black+Han+Sans&display=swap");
}

body {
    width: 100vw;
    font-family: "Barlow Semi Condensed", sans-serif !important;
    background-color: rgb(0, 0, 0);
    color: white;
    overflow-x: hidden;
}

.MuiMenu-list,
.MuiPaper-root {
    background-color: rgb(46, 46, 56) !important;
    color: white !important;
    padding-left: 5px !important;
    border-radius: 10px!important;
}

.MuiPopover-paper {
    border-radius: 10px!important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #808080 !important;
}

.Mui-selected {
    background-color: #1313138a !important;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #686868 #262626;
}


/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #262626;
}

*::-webkit-scrollbar-thumb {
    background-color: #686868;
    border-radius: 20px;
    border: 3px solid #262626;
}

button {
    font-family: Barlow Semi Condensed;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    letter-spacing: 0em;
    text-align: center;
}

a {
    color: white;
    text-decoration: none;
}